import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { utils, writeFile } from "xlsx"; // Import xlsx utils and writeFile for Excel export
import Header from "../dashboard/Header";

const StudentTable = () => {
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [selectedClass, setSelectedClass] = useState("");
  const [selectedDivision, setSelectedDivision] = useState("");
  const [error, setError] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();

  // Fetch all classes
  useEffect(() => {
    const fetchClasses = async () => {
      try {
        const response = await axios.get(
          "https://api.erpschools.in/api/class/classes"
        );
        setClasses(response.data);
      } catch (error) {
        console.error("Error fetching classes:", error);
        setError("Failed to load classes");
      }
    };

    fetchClasses();
  }, []);

  // Fetch divisions based on selected class
  useEffect(() => {
    const fetchDivisions = async () => {
      if (selectedClass) {
        try {
          const response = await axios.get(
            `https://api.erpschools.in/api/class/divisions/${selectedClass}`
          );
          setDivisions(response.data);
        } catch (error) {
          console.error("Error fetching divisions:", error);
          setError("Failed to load divisions");
        }
      }
    };

    fetchDivisions();
  }, [selectedClass]);

  // Fetch students based on selected class and division
  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedClass && selectedDivision) {
        try {
          const response = await axios.get(
            `https://api.erpschools.in/api/class/students/${selectedClass}/${selectedDivision}`
          );
          console.log(response);
          setStudents(response.data);
        } catch (error) {
          console.error("Error fetching students data:", error);
          setError("Failed to load students data");
        }
      }
    };

    fetchStudents();
  }, [selectedClass, selectedDivision]);

  // Handle download as Excel
  const handleDownloadExcel = () => {
    // Format student data for Excel export
    const dataForExcel = students.map((student) => ({
      "Registration No": student.regdNo,
      "First Name": student.firstName,
      "Middle Name": student.middleName || "",
      "Last Name": student.lastName,
      "UID No": student.uidNo,
      DOB: student.dob,
      "DOB Place": student.dobPlace,
      "Blood Group": student.bloodGrp,
      "Father's Name": student.fatherName,
      "Mother's Name": student.motherName,
      Religion: student.religion,
      Category: student.category,
      Nationality: student.nationality,
      "Mobile No": student.mobileNo,
      "Alternate Mobile No": student.alternateMobileNo,
      "Email ID": student.emailId,
      "Admission No": student.admNo,
      Class: student.class_id,
      Division: student.division_id,
      "Bus Route": student.busRoute,
      "Admission Date": new Date(student.admDate).toLocaleDateString(),
    }));

    const worksheet = utils.json_to_sheet(dataForExcel);
    const workbook = utils.book_new();
    utils.book_append_sheet(workbook, worksheet, "Students");

    // Download the file
    writeFile(workbook, "StudentData.xlsx");
  };

  const handleViewDetails = (student) => {
    navigate(`/student-details/${student.id}`);
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const handleCertificateTypeChange = (id, value, student) => {
    // Define URL for each certificate type
    // navigate('/id-form', { state: { student } });

    const certificateUrls = {
      Type1: `/id-card/${id}`,
      Type2: `/transfer-certificate/${id}`,
      Type3: `/bonafide-certificate/${id}`,
      Type4: `/nirgam-certificate/${id}`,
    };

    const selectedStudent = students.find((student) => student.id === id);

    // Update the students state with the selected certificate type
    setStudents(
      students.map((student) =>
        student.id === id ? { ...student, certificateType: value } : student
      )
    );

    // Navigate to the corr. URL with the selected student data as state
    if (certificateUrls[value] && selectedStudent) {
      navigate(certificateUrls[value], { state: { student: selectedStudent } });
    }
  };

  const filteredStudents = students.filter(
    (student) =>
      student.regdNo.toString().includes(searchQuery) ||
      student.class_id.toString().includes(searchQuery.toLowerCase()) ||
      student.mobileNo.includes(searchQuery) ||
      student.emailId.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <Header />
      <div className="container mx-auto p-4">
        {error && (
          <div className="bg-red-100 text-red-800 p-4 mb-4 rounded">
            {error}
          </div>
        )}

        {/* Class Selection */}
        <div className="mb-4">
          <label className="block mb-2">Select Class</label>
          <select
            value={selectedClass}
            onChange={(e) => setSelectedClass(e.target.value)}
            className="w-full p-2 border rounded"
          >
            <option value="">Select Class</option>
            {classes.map((cls) => (
              <option key={cls.id} value={cls.id}>
                {cls.class_name}
              </option>
            ))}
          </select>
        </div>

        {/* Division Selection */}
        {selectedClass && (
          <div className="mb-4">
            <label className="block mb-2">Select Division</label>
            <select
              value={selectedDivision}
              onChange={(e) => setSelectedDivision(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Division</option>
              {divisions.map((div) => (
                <option key={div.id} value={div.id}>
                  {div.division_name}
                </option>
              ))}
            </select>
          </div>
        )}

        {/* Download Button */}
        <div className="mb-4">
          <button
            onClick={handleDownloadExcel}
            className="bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600"
          >
            Download as Excel
          </button>
        </div>

        {/* Student Table */}
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white border border-gray-200">
            <thead>
              <tr>
                <th className="py-2 px-4 border-b">Photo</th>
                <th className="py-2 px-4 border-b">Registration No.</th>
                <th className="py-2 px-4 border-b">Class</th>
                <th className="py-2 px-4 border-b">Mobile No</th>
                <th className="py-2 px-4 border-b">Email ID</th>
                <th className="py-2 px-4 border-b">Actions</th>
                <th className="py-2 px-4 border-b">Certificate Type</th>
              </tr>
            </thead>
            <tbody>
              {filteredStudents.length > 0 ? (
                filteredStudents.map((student) => (
                  <tr key={student.id}>
                    <td className="py-2 px-4 border-b">
                      <div className="w-20 h-20 sm:w-44 sm:h-44 p-4">
                        <img
                          src={`https://api.erpschools.in${student.photo}`}
                          alt="Student"
                          className="w-full h-full object-cover"
                        />
                      </div>
                    </td>
                    <td className="py-2 px-4 border-b">{student.regdNo}</td>
                    <td className="py-2 px-4 border-b">{student.class_id}</td>
                    <td className="py-2 px-4 border-b">{student.mobileNo}</td>
                    <td className="py-2 px-4 border-b">{student.emailId}</td>
                    <td className="py-2 px-4 border-b">
                      <button
                        onClick={() => handleViewDetails(student)}
                        className="bg-blue-500 text-white py-1 px-4 rounded hover:bg-blue-600"
                      >
                        View Details
                      </button>
                      Certificate Type
                    </td>
                    <td className="py-2 px-4 border-b">
                      <select
                        value={student.certificateType}
                        onChange={(e) =>
                          handleCertificateTypeChange(
                            student.id,
                            e.target.value
                          )
                        }
                        disabled
                        className="border rounded py-1 px-2"
                      >
                        <option value="Select Certificate Type" disabled>
                          Select Certificate Type
                        </option>
                        <option value="Type1">Id_Card Certificate</option>
                        <option value="Type2">Transfer Certificate</option>
                        <option value="Type3">Bonafide Certificate</option>
                        <option value="Type4">Nirgam Certificate</option>
                      </select>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="7" className="py-2 px-4 border-b text-center">
                    No students found
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default StudentTable;
